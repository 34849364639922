<template>
    <Moderation />
</template>

<script>
import Moderation from '@apps/Moderation'
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        Moderation
    }
}
</script>